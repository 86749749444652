document.addEventListener("DOMContentLoaded", function() 
{

    let $body = $('body');
    if($body.hasClass("is-test-template")){
        let $supporting_carousel_container = $('#supportingCarouselContainer');
        let $supporting_carousel = $supporting_carousel_container.find('#supportingCarousel');
        let $carousel_control = $supporting_carousel_container.find(".carousel-control");
        $carousel_control.click(() => {
            return false;
        })


        let items = document.querySelectorAll('#supportingCarouselContainer .carousel .carousel-item');
        items.forEach((el) =>
        {
          const minPerSlide = 6;
          let next = el.nextElementSibling
          for (var i=1; i<minPerSlide; i++)
          {
            if (!next) {
              // wrap carousel by using first child
              next = items[0]
            }
            let cloneChild = next.cloneNode(true)
            el.appendChild(cloneChild.children[0])
            next = next.nextElementSibling
          }
        });
        $(document).ready(function(){
          $supporting_carousel.carousel({interval: false});
          $supporting_carousel.carousel('pause');
        });
    }


});