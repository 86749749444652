$(document).ready(function()
{
	var last_href = '';

    if($("body").hasClass("is-test-template"))
    {

        $('#mainNav a').click(function(e)
        {
            e.preventDefault();

            var the_id = $(this).attr('id');
            var the_href = $(this).attr('href');

			if ( the_id && the_id !== 'undefined' && the_id == 'hello')
			{
				removeNavs(); 
				last_href = '';
			}
			else if ( the_id && the_id !== 'undefined' && the_id == 'openLocation')
			{
	            if ($('#locationNav').hasClass('active'))
	            {
	            	removeNavs();  
	            }
	            else
	            {
	            	$('#locationNav').addClass('active');
		            $('#supportingNav').removeClass('active');		            
		            $('#featuredProducts').addClass('no-buttons');
	            }
			}
			else
			{
	            if ($('#supportingNav').hasClass('active'))
	            {
	            	if (the_href == last_href)
	            	{
	            		removeNavs();
	            	}
	            }
	            else
	            {
	            	$('#supportingNav').addClass('active');
		            $('#locationNav').removeClass('active');		            
		            $('#featuredProducts').addClass('no-buttons');
	            }
	        }
	        last_href = the_href;
        });



    }

    // esc key action
    $(document).keyup(function(e) 
    {
        if (e.keyCode == 27) 
        { // Esc
           removeNavs();
        }
    });

	function removeNavs()
	{
    	$('#supportingNav').removeClass('active');
    	$('#locationNav').removeClass('active');
        $('#featuredProducts').removeClass('no-buttons');
	}

    if ($('.sortable-table').length && $('.sortable-table').dataTable)
    {

    	let listTable = 
	        $('.sortable-table').dataTable(
	        {
	            "searching": true,
	            "info": false,
	            "paging": false,
	            "language": {
	                search: '<i class="fa fa-filter" aria-hidden="true"></i><span class="show-for-sr">Filter</span>',
	                searchPlaceholder: 'Search for a product'
	            },
	            "order": [[ 0, "asc" ]],
	            
	        });
        
			// Handle form submission event 
			$('#filterTableForm').on('submit', function(e)
			{
			   let form = this;

			   // Encode a set of form elements from all pages as an array of names and values
			   let params = listTable.$('input,select,textarea').serializeArray();

			   // Iterate over all form elements
			   $.each(params, function(){     
			      // If element doesn't exist in DOM
			      if(!$.contains(document, form[this.name])){
			         // Create a hidden element 
			         $(form).append(
			            $('<input>')
			               .attr('type', 'hidden')
			               .attr('name', this.name)
			               .val(this.value)
			         );
			      } 
			   });      
			});


    }

    if ($('.sortable-table-excel').length && $('.sortable-table-excel').dataTable)
    {

        var dateNow = new Date();
        var dateStr = dateNow.toUTCString()
        var the_title = "VB Screens Summary: " + dateStr;

        $('.sortable-table-excel').dataTable(
        {
            "searching": true,
            "info": false,
            "paging": false,
            "order": [[ 0, "asc" ]],
            "language": {
                search: '<i class="fa fa-filter" aria-hidden="true"></i><span class="show-for-sr">Filter</span>',
                searchPlaceholder: 'Search for a product'
            },

            "dom": 'Blfrtip',
            "buttons": [  
                { 
                    "extend" : "excelHtml5",
					"exportOptions" : {
						columns: [ 0, 1, 2, 3, 4, 5, 6 ]
					},
                    "filename" : the_title,
                    "text": 'View in Excel (More Columns)',
                    "title" : '',
                    //"title" : $(this).data("file-title"),
                }
            ],            
        });
    

    } 

    if ($('#reorderTable').length)
    {

		$("#reorderTable tbody").sortable(
		{  
	        delay: 150,  
	        stop: function() 
	        {

	            var count = 1;
	            $('#reorderTable tbody > tr').each(function() 
	            {  
	               	
	               	$(this).children("td.new-value").html(count);
	               	$(this).children("td.sort-order-value").children("input").val(count);

	                count++;
	            });  
	            //updateOrder(selectedData);  
	        }  
    	}); 
    } 


});

